import React from "react"
import { LayoutWhite } from "@layout"
import { HeroFeature2022Q3 } from "@components/hero"
import SEO from "@components/seo"
import {
  MainBlockAboutEmpowering,
  MainBlockAboutMeetTheTeam,
  MainBlockJoinColony,
} from "@components/main-blocks"

const About = () => (
  <LayoutWhite
    backgroundImage="about"
    hero={
      <HeroFeature2022Q3
        title="Our Mission"
        subtitle="We exist because we believe you deserve more money."
      />
    }
  >
    <SEO
      title="About Us, Our Mission &amp; Who We Are"
      description="ATM.com is on a mission to help you amass wealth through smart financial practices. By rewarding you for simple actions such as brand matching and completing surveys, we make every interaction count. Discover how we aim to empower you to earn, save, and invest more."
      image="/img/og/our-mission.jpg"
    />
    <MainBlockAboutEmpowering />
    <MainBlockAboutMeetTheTeam />
    <MainBlockJoinColony platform="both" />
  </LayoutWhite>
)

export default About
